import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import localforage from 'localforage'
import * as requests from '../../requests'
import '../../styles/login.css'
import warningImg from '../../assets/warning.svg'

const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/

export function Login ({ setToken }) {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [loginError, setLoginError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showErrors, setShowErrors] = useState(false)

  useEffect(() => {
    if (email === '') {
      setLoginError('Email could not be empty!')
    } else if (!email.match(emailformat)) {
      setLoginError('Incorrect email address!')
    } else {
      setLoginError('')
    }
    // check password
    if (pass === '') {
      setPasswordError('Password could not be empty!')
    } else {
      setPasswordError('')
    }
  }, [email, pass])

  const check = async event => {
    event.preventDefault()

    if (loginError || passwordError) {
      console.log('login or pass incorrect')
      setShowErrors(true)
    } else {
      const result = await requests.login(email, pass)
      if (!result.success) {
        console.log('login error', result)
        if (result.error) {
          setShowErrors(true)
          setPasswordError(result.error)
        } else {
          setPasswordError('')
        }
      } else {
        setShowErrors(false)
        console.log('login success', result)
        setToken(result.token)
        try {
          await localforage.setItem('token', result.token)
        } catch (err) {
          console.log('save token error', err)
        }
        navigate('/')
      }
    }
  }

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col'>
        <form>
          <fieldset className='min-w-0'>
            <h1 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Log in
            </h1>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mb-6'>
              New user?&nbsp;
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/register'
              >
                Create Dingo Account
              </Link>
            </p>
            <div className='mt-4'>
              <span
                dir='ltr'
                className='yeti-input__wrapper relative inline-block w-full'
              >
                <input
                  className={
                    'yeti-input border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker InputField__email ' +
                    (showErrors && loginError ? 'border-red' : 'border-grey')
                  }
                  type='email'
                  id='identifier_field'
                  name='identifier'
                  placeholder='Email address'
                  value={email}
                  onChange={event => {
                    setEmail(event.target.value)
                  }}
                />
              </span>
              <p
                className={
                  'yeti-text flex justify-center w-full text-center text-micro leading-normal mt-3 flex leading-none text-red ' +
                  (showErrors && loginError ? '' : 'hidden')
                }
              >
                <img className='mr-1' src={warningImg} alt='warning' />
                <strong className='self-center'>{loginError}</strong>
              </p>
            </div>
            <div className='mt-4'>
              <span
                dir='ltr'
                className='yeti-input__wrapper relative inline-block w-full '
              >
                <input
                  className={
                    'yeti-input border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker InputField__email ' +
                    (showErrors && passwordError ? 'border-red' : 'border-grey')
                  }
                  type='password'
                  id='pass_field'
                  name='pass'
                  placeholder='Password'
                  value={pass}
                  onChange={event => {
                    setPass(event.target.value)
                  }}
                />
              </span>
              <p
                className={
                  'yeti-text flex justify-center w-full text-center text-micro leading-normal mt-3 flex leading-none text-red ' +
                  (showErrors && passwordError ? '' : 'hidden')
                }
              >
                <img className='mr-1' src={warningImg} alt='warning' />
                <strong className='self-center'>{passwordError}</strong>
              </p>
            </div>
            <button
              // type='submit'
              className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue w-full mt-3'
              onClick={check}
            >
              Continue
            </button>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mt-4'>
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/reset'
              >
                Forgot your password?
              </Link>
            </p>
          </fieldset>
        </form>
        <div className='pt-8 mt-auto'>
          <Link
            className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border bg-transparent yeti-button--v-outlined yeti-button--c-grey text-grey-darker border-grey block text-grey-darkest text-small w-full mt-3 text-center'
            to='/codelogin'
          >
            <div className='flex items-center justify-center'>
              Email me a login code
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
