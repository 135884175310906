import React from 'react'

import windowsBadge from '../../assets/badge-windows-version.png'

export default function SubscriptionActivated (props) {
  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0 px-10'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <form>
          <fieldset className='min-w-0'>
            <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Status
            </h2>
            <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
              DingoVPN Premium subscription successfully activated!
            </div>
            <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
              If you do not have installed DingoVPN App get it by button below
            </div>
          </fieldset>
        </form>
        <div className='my-6'>
          {/* <a
            className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue w-full mt-3'
            href='https://dingovpn.com/download/YetiVPN_Client_Win.exe'
          >
            Get App
          </a> */}
          <div className='yeti-button focus:outline-none select-none ease-out duration-250 cursor-pointer'>
            <a
              className='dllink'
              href='https://dingovpn.com/dl/windows/direct/'
            >
              <img className='inline-flex' src={windowsBadge} alt='' style={{ height: '72px' }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
