import React from 'react'
import { Link } from 'react-router-dom'
import { Plan, Plans } from '../components/Plans'
import Download from '../components/Download'

import { isSubscribedCheck } from '../utils'

export default function AccountInfo (props) {
  const { account, plans } = props
  const isSubscribed = isSubscribedCheck(account)
  let selectedPlan = null
  if (isSubscribed) {
    if (account.isTester && !account.paymentInfo) {
      selectedPlan = plans.custom
    }
    else if (account.paymentInfo.source === 'paddle') {
      selectedPlan =
        plans.common.find(
          plan =>
            plan.providers.paddle.id.toString() ===
            account.paymentInfo.subscription_plan_id
        ) || plans.custom
    } else if (account.paymentInfo.source === 'yeticoin') {
      selectedPlan =
        plans.common.find(plan => plan.id === account.paymentInfo.planId) ||
        plans.custom
    } else if (account.paymentInfo.source === 'paypal') {
      selectedPlan =
        plans.common.find(
          plan => plan.providers.paypal.id === account.paymentInfo.plan_id
        ) || plans.custom
    }
  }

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
          Account
        </h2>
        {isSubscribed ? (
          <Active plans={plans} selectedPlan={selectedPlan} account={account} />
        ) : (
          <NotActive />
        )}
      </div>
      <Download />
    </div>
  )
}
         
function NotActive () {
  return (
    <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
      DingoVPN Premium subscription is not active
    </div>
  )
}

function Active ({ account, plans, selectedPlan }) {
  return (
    <>
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        DingoVPN Premium subscription is active
      </div>
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        Your current plan:
      </div>
      <CurrentSubscriptionPlan
        account={account}
        plans={plans}
        selectedPlan={selectedPlan}
      />
      <Cancel account={account} />
    </>
  )
}

function Cancel({ account, plans, selectedPlan }) {
  if (account.isTester && !account.paymentInfo) {
    return null
  } else if (account.subStatus.status === 'ACTIVE') {
    if (account.paymentInfo.source === 'paddle') {
      return (
        <div className='mt-6 flex row justify-around'>
          <Link
            className='yeti-button p-3 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue mt-3'
            to='/cancel'
          >
            Cancel Subscription
          </Link>
        </div>
      )
    } else if (account.paymentInfo.source === 'inapp') {
      return null
    }
  } else {
    const nextPaymentTime = account.subStatus.nextPaymentTime
    return (
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        Your subscription is cancelled, but will be active untill{' '}
        {nextPaymentTime}
      </div>
    )
  }
}

function CurrentSubscriptionPlan({ account, plans, selectedPlan }) {
  if (account.isTester && !account.paymentInfo) {
    return (
      <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
        TESTER MODE
      </div>
    )
  } else if (account.paymentInfo.source === 'paddle') {
    if (plans && selectedPlan) {
      return (
        <>
          <Plans
            plans={plans}
            selectedPlanID={selectedPlan.id}
            setSelectedPlanID={() => {}}
          />
          {/* <div className='yeti-row row justify-center'>
          <Plan
            plan={selectedPlan}
            selectedPlanID={selectedPlan.id}
            setSelectedPlanID={() => {}}
          />
        </div> */}
        </>
      )
    }
  } else if (account.paymentInfo.source === 'inapp') {
    if (account.paymentInfo.os === 'ios') {
      return (
        <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
          iOS subscription
        </div>
      )
    } else if (account.paymentInfo.os === 'android') {
      return (
        <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
          Android subscription
        </div>
      )
    }
  }

  return (
    <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
      Loading awailable subscription plans...
    </div>
  )
}
