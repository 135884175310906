import React, { useEffect, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import localforage from 'localforage'
import logo from '../assets/logo_inline.svg'
// import logo from '../assets/dingo_logo_text.png'
import { useResizeDetector } from 'react-resize-detector'

import {
  BSC,
  useEthers,
  useToken,
  useTokenBalance,
  useEtherBalance,
  useContractCalls,
  useGasPrice,
  useSendTransaction,
  useContractFunction
} from '@usedapp/core'

// import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber'
// import {
//   formatEther,
//   formatUnits,
//   parseEther,
//   parseUnits
// } from '@ethersproject/units'

import {
  DisconnectButton,
  InstallMetaMaskButton,
  MetaMaskButton,
  ToBSCButton,
  WatchYETIButton,
  WalletConnectButton,
  ToggleButton,
  YETIBalance,
  ConnectWalletButton,
  HeaderYETIBalance,
  ToBSCHeaderButton
} from './dapp'

const linksData = [
  // { path: '/', text: 'Home' },
  { path: '/account', text: 'Account' },
  { path: '/plans', text: 'Plans' },
  { path: '/apps', text: 'Apps' }
]

export default function Header ({
  account,
  setAccount,
  setToken,
  menuIsOpen,
  setMenuIsOpen
}) {

  const [menuLinks, setMenuLinks] = useState(linksData)
  useEffect(() => {
    if (account && account.isBusinessAccount) {
      const links = linksData.slice(0)
      links.splice(1, 0, { path: '/users', text: 'Users' })
      setMenuLinks(links)
    }
  }, [account])

  const onResize = useCallback(params => {
    // console.log('onResize', params)
    isMobile = window.innerWidth <= 742
  }, [])



  const { width, height, ref } = useResizeDetector({ onResize })
  let isMobile = window.innerWidth <= 742

  return (
    <header ref={ref} className='z-1 smd:shadow-1 bg-white'>
      <div className='yeti-container container'>
        <div
          className={`yeti-row row ${
            isMobile || !account ? 'py-4 md:py-6' : 'pt-4 md:pt-6'
          }`}
        >
          <Main
            isMobile={isMobile}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
          />
          <Wallet account={account} />
          <Auth account={account} setAccount={setAccount} setToken={setToken} />
        </div>
        <HeaderMenu account={account}  menuLinks={menuLinks} isMobile={isMobile} />
      </div>
    </header>
  )
}

function Main ({ account, menuIsOpen, setMenuIsOpen, isMobile }) {
  if (isMobile) {
    return (
      <div className='yeti-col col-4 flex items-center justify-start'>
        <div
          className={`burger ml-1 ${menuIsOpen ? 'burger--active' : ''}`}
          data-follow
          onClick={() => {
            setMenuIsOpen(!menuIsOpen)
          }}
        >
          <div className='burger__line' />
          <div className='burger__line' />
          <div className='burger__line' />
        </div>
      </div>
    )
  }

  return (
    <div className='yeti-col col-4 flex items-center justify-start'>
      <Link
        className='yeti-link flex items-center justify-center cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
        to='/'
      >
        <img
          src={logo}
          className='headerlogo mr-3 flex items-center justify-center '
          alt='logo'
        />
        {/* <span className='hidden lg:inline '>DingoVPN Premium</span> */}
      </Link>
    </div>
  )
}

function HeaderMenu ({ account, menuLinks, isMobile }) {
  const location = useLocation()

  if (isMobile || !account) {
    return null
  }

  const links = menuLinks.map(({ path, text }) => (
    <Link
      key={path}
      className={`menu-item yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light ${
        location.pathname === path ? 'active' : ''
      }`}
      to={path}
    >
      {text}
    </Link>
  ))


  return (
    <div className='header-menu yeti-row row py-4 md:py-6 align-center justify-center'>
      {links}
    </div>
  )
}

function Wallet ({ account }) {
  const [installed, setInstalled] = useState(false)
  const [connectorName, setConnectorName] = useState('')
  const [hideButtons, setHideButtons] = useState(false)
  const [walletAddress, setWalletAddress] = useState(null)
  const [allowAutoHide, setAllowAutoHide] = useState(null)

  const ethers = useEthers()
  // const { account, chainId, library, error } = ethers

  useEffect(() => {
    if (window.ethereum) {
      setInstalled(true)
    }
  }, [])

  if (!account || !installed)
    return (
      <div className='yeti-col col-4 flex items-center justify-center'></div>
    )

  return (
    <div className='yeti-col col-4 flex items-center justify-center'>
      <div className='header-wallet row'>
        <div className='header-balance'>
          <HeaderYETIBalance />
        </div>
        <ConnectWalletButton
          installed={installed}
          setAllowAutoHide={setAllowAutoHide}
          ethers={ethers}
          setConnectorName={setConnectorName}
        />
        <ToBSCHeaderButton ethers={ethers} />
        <ToggleButton
          hideButtons={hideButtons}
          setHideButtons={setHideButtons}
          setAllowAutoHide={setAllowAutoHide}
          ethers={ethers}
        />
      </div>
    </div>
  )
}

function Auth ({ account, setAccount, setToken }) {
  return (
    <div className='yeti-col col-4 flex items-center justify-end'>
      <p className='yeti-text text-small leading-normal text-grey font-medium select-none mr-1'>
        <span className='hidden lg:inline'>
          {account ? account.email : 'Already have Dingo Account?'}
        </span>
        {account ? (
          <Link
            className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose ml-3'
            to='/login'
            onClick={async e => {
              setAccount(null)
              setToken(null)
              try {
                await localforage.removeItem('token')
              } catch (err) {
                console.log('save token error', err)
              }
            }}
          >
            Log out
          </Link>
        ) : (
          <Link
            className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose ml-3'
            to={'/login'}
          >
            Log in
          </Link>
        )}
      </p>
    </div>
  )
}
