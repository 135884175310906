import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar'
import localforage from 'localforage'
import * as requests from '../../requests'
import '../../styles/login.css'
import warningImg from '../../assets/warning.svg'

const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/

export function Reset({ initEmail }) {
  const navigate = useNavigate()
  const [email, setEmail] = useState(initEmail || '')

  useEffect(() => {
    setEmail(initEmail || '')
  }, [initEmail])

  const check = async event => {
    event.preventDefault()
    if (email === '') {
      // this.emptyFields = true
    } else {
      const result = await requests.resetcode(email)

      if (!result.success) {
        console.log('Reset error', result)
        // this.emptyFields = true
        // this.errorMessage = result.errorMessage || 'Unknown Error!'
      } else {
        console.log('Reset success', result)
        // setToken(result.token)
        // this.errorMessage = ''
        // this.emptyFields = false
        // this.$router.push('/home')
        navigate('/resetcode')
      }
    }
  }

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col'>
        <form>
          <fieldset className='min-w-0'>
            <h1 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Reset Password
            </h1>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mb-6'>
              New user?&nbsp;
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/register'
              >
                Create Dingo Account
              </Link>
            </p>
            <div className='mt-4'>
              <span
                dir='ltr'
                className='yeti-input__wrapper relative inline-block w-full'
              >
                <input
                  className='yeti-input border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker border-grey InputField__email'
                  type='email'
                  id='identifier_field'
                  name='identifier'
                  placeholder='Email address'
                  value={email}
                  onChange={event => {
                    setEmail(event.target.value)
                  }}
                />
              </span>
            </div>

            <button
              // type='submit'
              className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue w-full mt-3'
              onClick={check}
            >
              Send Code
            </button>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mt-4'>
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/resetcode'
              >
                Already have Reset Code?
              </Link>
            </p>
          </fieldset>
        </form>
        <div className='pt-8 mt-auto'>
          <Link
            className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border bg-transparent yeti-button--v-outlined yeti-button--c-grey text-grey-darker border-grey block text-grey-darkest text-small w-full mt-3 text-center'
            to='/codelogin'
          >
            <div className='flex items-center justify-center'>
              Email me a login code
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
