import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar'
import localforage from 'localforage'
import * as requests from '../../requests'
import '../../styles/login.css'
import warningImg from '../../assets/warning.svg'

export function UpdatePassword ({ setToken, resetCode }) {
  const navigate = useNavigate()
  const [code, setCode] = useState(resetCode || '')
  const [pass, setPass] = useState('')

  useEffect(() => {
    setCode(resetCode || '')
  }, [resetCode])

  const check = async event => {
    event.preventDefault()
    if (code === '' || pass === '') {
      // this.emptyFields = true
    } else {
      const result = await requests.updatePass(code, pass)

      if (!result.success) {
        console.log('updatePass error', result)
        // this.emptyFields = true
        // this.errorMessage = result.errorMessage || 'Unknown Error!'
      } else {
        console.log('updatePass success', result)
        setToken(result.token)
        try {
          await localforage.setItem('token', result.token)
        } catch (err) {
          console.log('save token error', err)
        }
        // this.errorMessage = ''
        // this.emptyFields = false
        // this.$router.push('/home')
        navigate('/')
      }
    }
  }

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0'>
      <div className='Card smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col'>
        <form>
          <fieldset className='min-w-0'>
            <h1 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Update Password
            </h1>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mb-6'>
              New user?&nbsp;
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/register'
              >
                Create Dingo Account
              </Link>
            </p>
            <div className='mt-4'>
              <span
                dir='ltr'
                className='yeti-input__wrapper relative inline-block w-full'
              >
                <input
                  className='yeti-input border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker border-grey InputField__email'
                  type='text'
                  id='identifier_field'
                  name='identifier'
                  placeholder='Reset Code'
                  value={code}
                  onChange={event => {
                    setCode(event.target.value)
                  }}
                />
              </span>
            </div>
            <div className='mt-4'>
              <span
                dir='ltr'
                className='yeti-input__wrapper relative inline-block w-full '
              >
                <input
                  className='yeti-input border rounded w-full align-bottom leading-normal outline-none appearance-none p-3 InputField bg-transparent text-grey-darker border-grey InputField__email'
                  type='password'
                  id='pass_field'
                  name='pass'
                  placeholder='Password'
                  value={pass}
                  onChange={event => {
                    setPass(event.target.value)
                  }}
                />
              </span>
              <PasswordStrengthBar className='mt-4 mb-6' password={pass} />
            </div>
            <button
              type='button'
              className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue w-full mt-3'
              onClick={check}
            >
              Continue
            </button>
            <p className='yeti-text text-small leading-normal text-grey-dark font-medium text-center mt-4'>
              <Link
                className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-blue hover:text-blue-light'
                to='/reset'
              >
                Do not get Reset Code?
              </Link>
            </p>
          </fieldset>
        </form>
        <div className='pt-8 mt-auto'>
          <Link
            className='yeti-button focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border bg-transparent yeti-button--v-outlined yeti-button--c-grey text-grey-darker border-grey block text-grey-darkest text-small w-full mt-3 text-center'
            to='/codelogin'
          >
            <div className='flex items-center justify-center'>
              Email me a login code
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
