import React, { useEffect, useState, useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Flag from '../Servers/Flag'
import * as requests from '../../requests'
import './configs.css'
import locations from '../locations'
import servers from './servers'
import RemoveDialog from './removeDialog'
import Servers from '../Servers'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

import logo from '../../assets/logo_inline.svg'

const maxConfigs = 5

export default function StaticConfig(props) {
  const { token, isSubscribed } = props

  const [configsList, setConfigsList] = useState(null)
  const [selectedConfigIndex, setSelectedConfigIndex] = useState(null)
  const [refreshId, setRefreshId] = useState(0)

  useEffect(() => {
    const run = async () => {
      const result = await requests.getStaticConfigsList(token)
      if (!result.success) {
        console.log('getStaticConfigsList failed', result)
        if (result.error) {
          console.log('getStaticConfigsList error', result.error)
        }
      } else {
        const configs = result.configs.filter(el => {
          return !!el.configFmt
        })
        console.log('getStaticConfigsList', configs)
        setConfigsList(configs)
        setSelectedConfigIndex(configs.length ? 0 : null)
      }
    }
    if (token) {
      run()
    }
  }, [token, refreshId])

  const [serversPlane, setServersPlane] = useState([])

  useEffect(() => {
    const plane = []
    servers.map(item => {
      const srvs = item.data.map(srv => {
        const country = locations.country[srv.countryCode]
        const city = locations.city[srv.id]
        const info = {
          ...srv,
          region: item.region,
          country,
          city,
        }
        plane.push(info)
        return info
      })
      return {
        region: item.region,
        data: srvs
      }
    })

    setServersPlane(plane)
    console.log('serversPlane', plane)
  }, [servers])

  if (!isSubscribed) {
    return null
  }

  return (
    <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
      <h5 className='yeti-text text-h5 leading-normal font-medium tracking-tight mb-6 text-center'>
        Wireguard Static Configs
      </h5>
      <div className='configsWrapper'>
        <ConfigsList
          list={configsList}
          selected={selectedConfigIndex}
          select={setSelectedConfigIndex}
          serversPlane={serversPlane}
          token={token}
          setRefreshId={setRefreshId}
        />

      </div>
    </div>
  )
}


function ConfigsList(props) {
  const { list, select, selected,
    serversPlane, token, setRefreshId } = props

  const [showQR, setShowQR] = useState(true)
  const [createMode, setCreateMode] = useState(false)
  const qrRef = useRef(null)

  useEffect(() => {
    if (list && !list.length && !createMode) {
      setCreateMode(true)
    }
  }, [list, createMode])

  if (!list) {
    return (
      <div className='Loading'>
        <p className='text-center'>Loading...</p>
      </div>
    )
  }

  const activeConfig = list[selected]
  const activeConfigText = list[selected]?.configFmt || ''
  const fixedText = activeConfigText.replace(/\n\n/g, '\n')
  // const aid = list[selected].id
  // const locInfo = serversPlane[list[selected].id]
  // let name = aid
  // if (locInfo && locInfo.city && locInfo.country) {
  //   name = `${locInfo.city}, ${locInfo.country}`
  // }

  return (
    <div className='ConfigsListWrapper'>
      <Box
        sx={{ margin: '0vh 1vw 2vh 1vw' }}
      >
        {`Active configs: ${list.length} of ${maxConfigs}`}
      </Box>
      <div className='ConfigsListContent'>
        {list.length ? <div className='ConfigsList'>
          <List dense={false}>
            {list.length && list.length < maxConfigs ?
              <Button
                variant='outlined'
                className={'actionButton'}
                sx={{ marginBottom: '1vh' }}
                onClick={() => {
                  setCreateMode(!createMode)
                }}>
                {createMode ? 'Cancel' : 'New Config'}
              </Button> : null
            }
            {list.map((config, index) => {
              const selectedClass = index === selected ? 'configItem selected' : 'configItem'
              const locInfo = serversPlane.find(el => el.id === config.location)
              // console.log('srv', index, config, locInfo)
              let name = config.id
              let countryCode = 'un'
              if (locInfo && locInfo.city && locInfo.country) {
                name = `${locInfo.city}, ${locInfo.country}`
              }
              // console.log('srv location', config.location, locInfo)
              if (config?.location && locInfo && locInfo.countryCode) {
                countryCode = locInfo.countryCode
              }
              return (
                <ListItem
                  key={index}
                  className={selectedClass}
                  onClick={() => {
                    select(index)
                  }}
                >
                  <ListItemIcon>
                    {/* <FolderIcon /> */}
                    <Flag country={countryCode} size={32} />
                  </ListItemIcon>
                  <ListItemText
                    primary={config.clientId}
                    secondary={name || config.location}
                  />
                </ListItem>
                // <div
                //   className={selectedClass}
                //   key={index}
                //   onClick={() => {
                //     select(index)
                //   }}>
                //   <div className='text-center'>{config.clientId}</div>
                //   <div className='text-center'>{name || config.location}</div>
                // </div>
              )

            })}
          </List>
        </div>
          : null}
        <div className='ConfigW'>
          {createMode ? <CreateConfig
            token={token}
            setCreateMode={setCreateMode}
            setRefreshId={setRefreshId}
            list={list}
          /> : <>
            <ConfigActionButtons
              text={fixedText}
              showQR={showQR}
              setShowQR={setShowQR}
              qrRef={qrRef}
              activeConfig={activeConfig}
              token={token}
              setRefreshId={setRefreshId}
            />
            <CurrentConfig
              text={fixedText}
              showQR={showQR}
              qrRef={qrRef}
            />
          </>}
        </div>
      </div>
    </div >

  )
}

function CreateConfig(props) {
  const { token, setCreateMode, setRefreshId, list } = props
  const [selectedServer, setSelectedServer] = useState(null)
  const [configName, setConfigName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [isDefaultName, setIsDefaultName] = useState(true)
  const [isAwaiting, setIsAwaiting] = useState(false)

  const validate = () => {
    if (!selectedServer) {
      setShowErrors(true)
      setErrorMessage('Please select a server')
      return false
    } else if (!configName) {
      setErrorMessage('Config name is required')
      setShowErrors(true)
      return false
    }
    console.log('valid', configName, selectedServer)
    setShowErrors(false)
    return true
  }

  useEffect(() => {
    if (selectedServer && isDefaultName) {
      if (list && list.length) {
        const sameLocation = list.filter(el => el.location === selectedServer.id)
        console.log('set default ConfigName', selectedServer)
        setConfigName(`${selectedServer.enName}#${sameLocation.length + 1}`)
      } else {
        console.log('set default ConfigName', selectedServer)
        setConfigName(`${selectedServer.enName}#1`)
      }

    }
  }, [selectedServer, isDefaultName])

  useEffect(() => {
    if (showErrors) {
      validate()
    }
  }, [showErrors, selectedServer, configName])

  return (
    <div className='CreateConfig'>
      <Grid
        item
        container
        xs
        justifyContent='space-around'
        className='CreateConfigForm'
        sx={{
          padding: '0vh 2vw',
        }}
      >
        <Field
          value={configName}
          setValue={(value) => {
            if (value) {
              setIsDefaultName(false)
            } else {
              setIsDefaultName(true)
            }
            setConfigName(value)
          }}
          label={'Config Name'}
          placeholder={'Home Router Paris'}
          errorMessage={errorMessage}
          showErrors={showErrors}
          sx={{
            flex: '1 1 50%',
            marginRight: '1vw',
          }}
        />
        <Button
          // fullWidth
          variant='outlined'
          size='large'
          // type="submit"
          // className={'actionButton'}
          onClick={async () => {
            if (validate()) {
              console.log('create config')
              setIsAwaiting(true)
              const result = await requests.createStaticConfig(configName, selectedServer.id, token)
              setIsAwaiting(false)
              if (!result.success) {
                console.log('createStaticConfig failed', result)
              } else {
                setRefreshId(Math.random())
                setCreateMode(false)
              }
            }
          }}>
          {isAwaiting ? <CircularProgress color='inherit' size={20} /> : 'Create Config'}
        </Button>
      </Grid>
      <Servers
        servers={servers}
        selectedServer={selectedServer}
        setSelectedServer={setSelectedServer}
      />
    </div>
  )
}

function ConfigActionButtons(props) {
  const { text,
    showQR, setShowQR,
    qrRef, activeConfig,
    token, setRefreshId } = props

  const [deleteAwaiting, setDeleteAwaiting] = useState(false)
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  if (!activeConfig) {
    return null
  }

  let QRAction = null
  const className = 'actionButton'
  if (showQR) {
    QRAction = (
      <Button
        variant='outlined'
        className={className}
        onClick={() => {
          const anchor = document.createElement('a')
          const canvas = qrRef.current.children[0]
          anchor.href = canvas.toDataURL('image/png')
          anchor.download = `DingoVPN_WireGuard_${activeConfig.clientId}.png`;
          anchor.click();
        }}>
        Save QR
      </Button>
    )
  } else {
    QRAction = (
      <CopyToClipboard text={text}
        onCopy={() => { console.log('copied') }}>
        <Button
          variant='outlined'
          className={className}
        >
          Copy
        </Button>
      </CopyToClipboard>
    )
  }

  return (
    <div className='ConfigsListButtons'>
      <Button
        variant='outlined'
        className={className}
        onClick={() => {
          setShowQR(!showQR)
        }}>
        {showQR ? 'Show Text' : 'Show QR'}
      </Button>

      {QRAction}

      <Button
        variant='outlined'
        className={className}
        onClick={async () => {
          setShowRemoveDialog(true)
          // setDeleteAwaiting(true)
          // const result = await requests.deleteStaticConfig(activeConfig._id, token)
          // setDeleteAwaiting(false)
          // if (!result.success) {
          //   console.log('deleteStaticConfig failed', result)
          // } else {
          //   setRefreshId(Math.random())
          // }
        }}>
        {deleteAwaiting ? <CircularProgress color='inherit' size={20} /> : 'DELETE'}
      </Button>
      <RemoveDialog
        open={showRemoveDialog}
        setOpen={setShowRemoveDialog}
        onApprove={async () => {
          console.log('RemoveDialog approve')
          setShowRemoveDialog(false)
          setDeleteAwaiting(true)
          const result = await requests.deleteStaticConfig(activeConfig._id, token)
          setDeleteAwaiting(false)
          if (!result.success) {
            console.log('deleteStaticConfig failed', result)
          } else {
            setRefreshId(Math.random())
          }
        }}
        onCancel={() => {
          console.log('RemoveDialog cancel')
          setShowRemoveDialog(false)
        }}
      />
    </div>
  )
}

function CurrentConfig(props) {
  const { text, showQR, qrRef } = props
  if (!text) {
    return null
  }

  const QR = <div className='configQRCodeWrapper' ref={qrRef}>
    <QRCodeCanvas
      className='configQRCode'
      value={text}
      size={256}
      bgColor={'#ffffff'}
      fgColor={'#2a7dcb'}
      level={"M"}
      includeMargin={true}
      imageSettings={{
        src: "./qr_logo.png",
        x: 92,
        // y: 0,
        height: 48,
        width: 48,
        excavate: false,
      }}
    />
  </div>

  return (
    <div className='ConfigContent'>
      {showQR ? QR : null}

      {!showQR ? <textarea
        className='configFmt notranslate'
        readOnly
        spellCheck={false}
        name="configFmt"
        rows="10"
        cols="68"
        value={text}
      /> : null}
    </div>
  )
}

function Field(params) {
  const {
    value,
    setValue,
    label,
    type,
    placeholder,
    errorMessage,
    showErrors,
    sx,
  } = params
  return (
    <TextField
      fullWidth
      variant='outlined'
      type={type}
      value={value}
      onChange={event => setValue(event.target.value)}
      error={showErrors && !!errorMessage}
      label={label || placeholder}
      placeholder={placeholder}
      helperText={showErrors && errorMessage}
      sx={sx}
    />
  )
}