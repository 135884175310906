export async function login (login, pass) {
  try {
    const response = await POST('https://api.dingovpn.com/account/login', {
      type: 'email',
      login,
      pass
    })
    console.log('login response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('login error', error)
    return { success: false }
  }
}

export async function register (login, pass) {
  try {
    const response = await POST('https://api.dingovpn.com/account/register', {
      type: 'email',
      login,
      pass
    })
    console.log('register response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('register error', error)
    return { success: false }
  }
}

export async function resetcode (email) {
  try {
    const response = await POST('https://api.dingovpn.com/account/code', {
      email
    })
    console.log('resetcode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('resetcode error', error)
    return { success: false }
  }
}

export async function updatePass (code, password) {
  try {
    const response = await POST('https://api.dingovpn.com/account/reset', {
      code,
      password
    })
    console.log('updatePass response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('updatePass error', error)
    return { success: false }
  }
}

export async function otccode (email) {
  try {
    const response = await POST('https://api.dingovpn.com/account/otcsend', {
      email
    })
    console.log('otccode response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('otccode error', error)
    return { success: false }
  }
}

export async function otclogin (email, code) {
  try {
    const response = await POST('https://api.dingovpn.com/account/otccheck', {
      email,
      code
    })
    console.log('otclogin response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('otclogin error', error)
    return { success: false }
  }
}

export async function cancelSubscription (subId, token, reason) {
  try {
    const response = await POST('https://api.dingovpn.com/account/cancel', {
      subId,
      token,
      reason
    })
    console.log('cancelSubscription response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('cancelSubscription error', error)
    return { success: false }
  }
}

export async function businessInvite (email, token) {
  try {
    const response = await POST('https://api.dingovpn.com/account/business/invite', {
      email,
      token
    })
    console.log('invite response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('invite error', error)
    return { success: false }
  }
}

export async function businessRemoveUser (id, token) {
  try {
    const response = await POST('https://api.dingovpn.com/account/business/remove', {
      id,
      token
    })
    console.log('remove response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('remove error', error)
    return { success: false }
  }
}

export async function getStaticConfigsList (token) {
  try {
    const response = await POST('https://api.dingovpn.com/account/configs/list', {
      token
    })
    const data = await response.json()
    console.log('getStaticConfigsList response', data)
    return data
  } catch (error) {
    console.log('getStaticConfigsList error', error)
    return { success: false }
  }
}

export async function deleteStaticConfig (peerId, token) {
  try {
    const response = await POST('https://api.dingovpn.com/account/configs/delete', {
      peerId,
      token
    })
    console.log('deleteStaticConfig response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('deleteStaticConfig error', error)
    return { success: false }
  }
}

export async function createStaticConfig (name, location, token) {
  try {
    const response = await POST('https://api.dingovpn.com/account/configs/create', {
      name,
      location,
      token
    })
    console.log('getStaticConfig response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('getStaticConfig error', error)
    return { success: false }
  }
}


export async function sendYetiReceipt ({ token, subId, months, coin, tx, signature }) {
  try {
    const response = await POST('https://api.dingovpn.com/receipt', {
      coin,
      token,
      subId,
      months,
      tx,
      signature
    })
    console.log('sendYetiReceipt response', response)
    const data = await response.json()
    return data
  } catch (error) {
    console.log('sendYetiReceipt error', error)
    return { success: false }
  }
}

export async function POST (url, body) {
  const response = await fetch(url, {
    method: 'POST',
    // mode: 'no-cors', // no-cors, *cors, same-origin
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(body)
  })
  return response
}

