import React from 'react'

import windowsBadge from '../assets/badge-windows-version.png'
// import windows32Badge from '../assets/badge-windows-x86.png'
import macosBadge from '../assets/badge_macos.png'
// import macosUniversalBadge from '../assets/badge_macos_universal.png'
// import macosArmBadge from '../assets/badge_macos_applesilicon.png'
import appStoreBadge from '../assets/badge_ios.svg'
import googlePlayBadge from '../assets/google-play-badge.png'

export default function Download() {
  return (
    <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
      <h5 className='yeti-text text-h5 leading-normal font-medium tracking-tight mb-6 text-center'>
        Download DingoVPN
      </h5>
      <div className='yeti-button'>
        <div className='dl-badges'>
          <div className='dl-badge-group inline-flex'>
            <a
              className='mx-2 focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://apps.apple.com/us/app/dingovpn-fast-secure/id1634385996'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='inline-flex'
                src={appStoreBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>

            <a
              className='mx-2 focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://play.google.com/store/apps/details?id=com.dingovpn'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='inline-flex'
                src={googlePlayBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
          </div>
          <div className='dl-badge-group inline-flex'>
            <a
              className='dllink mx-2 focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://dingovpn.com/instructions.html'
            >
              <img
                className='inline-flex'
                src={windowsBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
            <a
              className='dllink mx-2 focus:outline-none select-none ease-out duration-250 cursor-pointer'
              href='https://dingovpn.com/macosinstructions.html'
            >
              <img
                className='inline-flex'
                src={macosBadge}
                alt=''
                style={{ height: '72px' }}
              />
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}
